/* global $ */

class App
{
    bootstrap;

    constructor()
    {
        this.disableLogger();
        this.getBootstrap();
        this.initTooltips($('[data-bs-toggle="tooltip"]'));
        this.initToasts($('.toast'));
        this.initForms($('form'));
        this.initMatrixBlocks();
        this.initWhiteMenu();
        this.initSubmenu();
        this.fixBannerHeight();
        this.initStickyMenu();
        this.fileUpload();
        this.footerLogos();
        this.fixFooterHeight();
        this.initVideo();
        $('body').css('opacity', 1);
        $(window).scroll(() => {
            this.initStickyMenu();
            this.initSubmenu();
            $('#smallNav').css('bottom', -80);
            $.data(this, 'scrollTimer', setTimeout(function() {
                $('#smallNav').css('bottom', 0);
            }, 500));
        });
        $(window).resize(() => {
            this.fixBannerHeight();
            this.fixFooterHeight();
            this.initSubmenu();
        });
        console.log('App initialized');
    }

    getBootstrap()
    {
        if (!this.bootstrap) {
            this.bootstrap = import(/* webpackChunkName: "bootstrap" */ 'bootstrap');
        }
        return this.bootstrap;
    }

    initVideo() {
        if ($('.video-container').length) {
            $('.video-container').each(function() {
                $(this).click(() => {
                    let videoCont = $(this).find('.vdo-media');
                    let videoFrame = videoCont.find('iframe');
                    let videoUrl = videoCont.data('video');
                    videoCont.css('z-index', '3');
                    videoFrame.attr('src', videoUrl);
                });
            });
        }
    }

    disableLogger()
    {
        if (window.Globals.env == 'production') {
            console.log = function() {};
        }
    }

    initMatrixBlocks()
    {
        if ($('.matrix-block').length) {
            import(/* webpackChunkName: "matrixContent" */ './components/matrixContent');
        }
    }

    initForms($forms)
    {
        if ($forms.length) {
            import(/* webpackChunkName: "form" */ './components/form').then(chunk => {
                $.each($forms, (i, elem) => {
                    new chunk.Form($(elem));
                });
            });
        }
    }

    initToasts($elems)
    {
        if ($elems.length) {
            this.getBootstrap().then((bootstrap) => {
                $.each($elems, function (i, elem) {
                    new bootstrap.Toast(elem);
                });
            });
        }
    }

    initTooltips($elems)
    {
        if ($elems.length) {
            this.getBootstrap().then((bootstrap) => {
                $.each($elems, function (i, elem) {
                    new bootstrap.Tooltip(elem);
                });
            });
        }
    }

    initWhiteMenu() {
        $('.search-toggle').click(() => {
            if ($('.search-toggle').hasClass('collapsed')) {
                $('#header').removeClass('menu-open');
            } else {
                $('#header').addClass('menu-open');
            }
        });
        $('.megamenu-toggler').click(() => {
            if ($('.megamenu-toggler').hasClass('collapsed')) {
                $('#header').removeClass('menu-open');
            } else {
                $('#header').addClass('menu-open');
            }
            let indicatorOffset = $('.top-nav-link.active').position();
            $('.menu-indicator').css({'top': indicatorOffset.top, 'left': indicatorOffset.left});
        });
    }

    initSubmenu() {
        $('.top-nav-link').each(function() {
            $(this).hover(() => {
                let target = '.' + $(this).data('menu-target');
                let themeColor = 'var(--bs-' + $(this).data('theme-color') + ')';
                let indicatorOffset = $(this).position();
                $('.menu-indicator').css({'top': indicatorOffset.top, 'left': indicatorOffset.left});
                $('.submenu').removeClass('show');
                $(target).addClass('show');
                $('#nav-container').css("border-color", themeColor);
            });
            if ($(window).width() < 992) {
                $(this).click(() => {
                    $('.megamenu-toggler').removeClass('pe-auto').addClass('pe-none opacity-0');
                });
            }
        });
        if ($(window).width() < 992) {
            $('.search-container').height($(window).innerHeight());
        }
        $('.mobile-sub-toggler').each(function() {
            if ($(window).width() < 992) {
                $(this).click(() => {
                    $('.megamenu-toggler').removeClass('pe-none opacity-0').addClass('pe-auto');
                });
            }
        });
    }

    initStickyMenu() {
        let scrollTop = $(window).scrollTop();
        if ($(".header-banner").length && $('body').hasClass('home')) {
            let bannerHeight = $(".header-banner").outerHeight();
            if (scrollTop > (bannerHeight / 2)) {
                $('#header').addClass('scrolled');
            } else {
                $('#header').removeClass('scrolled');
            }
            if($('.banner-overlay').length) {
                let opacity = ((bannerHeight / 1.5) - scrollTop) / (bannerHeight / 1.5);
                $('.banner-overlay').css('opacity', opacity);
            }
        } else {
            let bannerHeight = $(".header-banner").outerHeight();
            $('#header').addClass('scrolled');
            if($('.banner-overlay').length) {
                let opacity = ((bannerHeight / 3.5) - scrollTop) / (bannerHeight / 3.5);
                $('.banner-overlay').css('opacity', opacity);
            }
        }
        if ($('.page-heading').length) {
            if ($(".header-banner").length && scrollTop < $(".header-banner").height()) {
                $('.page-heading').addClass('position-absolute').removeClass('position-fixed').css('top', '0');
            } else {
                $('.page-heading').addClass('position-fixed').removeClass('position-absolute').css('top', $('.header-top').height());
            }
        }
        if ($('.header-height').length) {
            $('.header-height').height($('.header-top').height());
        }
    }

    fileUpload() {
        $('.file-upload').each(function() {
            let input = $(this).find('input');
            let label = $(this).find('label');
            $('<span> - Upload File</span>').appendTo(label);
            input.change(() => {
                let file = input.val().split("\\").pop();
                if(file.length > 25){
                    file = file.substr(0,22) + "...";
                }
                label.html(file);
            });
        });
    }
    fixBannerHeight() {
        if ($('.home-banner').length) {
            $('.header-banner').height($(window).innerHeight()).width($(window).innerWidth());
            $('.banner-slider').height($(window).innerHeight()).width($(window).innerWidth());
            $('.banner-slide').height($(window).innerHeight()).width($(window).innerWidth());
            $('.bnr-img').height($(window).innerHeight()).width($(window).innerWidth());
            if ($(window).width() < 768) {
                $('.header-banner').height($(window).innerWidth() / 0.64).width($(window).innerWidth()).css('margin-top', $('.header-top').height());
                $('.banner-slider').height($(window).innerWidth() / 0.64).width($(window).innerWidth());
                $('.banner-slide').height($(window).innerWidth() / 0.64).width($(window).innerWidth()).css('margin-top', $('.header-top').height());
                $('.bnr-img').height($(window).innerWidth() / 0.64).width($(window).innerWidth());
            }
        } else if ($('.standard-banner').length) {
            if ($('.page-title').length) {
                $('.header-banner').height($(window).innerHeight() - $('.page-title').height() - $('.header-top').height()).css('margin-top', $('.header-top').height());
                $('.bnr-img').height($(window).innerHeight() - $('.page-title').height() - $('.header-top').height());
                if ($(window).width() < 768) {
                    $('.header-banner').height($(window).innerWidth() / 0.83);
                    $('.bnr-img').height($(window).innerWidth() / 0.83);
                }
            } else {
                $('.header-banner').height($(window).innerHeight() - $('.header-top').height()).css('margin-top', $('.header-top').height());
                $('.bnr-img').height($(window).innerHeight() - $('.header-top').height());
                if ($(window).width() < 768) {
                    $('.header-banner').height($(window).innerWidth() / 0.83);
                    $('.bnr-img').height($(window).innerWidth() / 0.83);
                }
            }
        }
        if ($('.page-heading').length) {
            $('.page-heading-height').height($('.page-heading').height());
        }
    }

    fixFooterHeight() {
        if ($(window).width() < 768) {
            $('.footer-top-mobile').css('min-height', $(window).innerHeight() - $('.header-top').height() - $('.footer-mid-mobile').height() - $('.footer-bottom-mobile').height() + 2);
        } else {
            $('.footer-top').css('min-height', $(window).innerHeight() - $('.header-top').height() - $('.footer-bottom').height() + 2);
        }
    }

    footerLogos() {
        if ($(window).width() < 768) {
            $('.sponsor-logo').each(function() {
                $(this).click(() => {
                    $('.sponsor-logo').not(this).removeClass('focus').addClass('hide');
                    $(this).addClass('focus');
                    $('.sponsor-close').addClass('show');
                });
            });
            $('.sponsor-close').click(() => {
                $('.sponsor-logo').removeClass('hide');
                $('.sponsor-logo.focus').removeClass('focus');
                $('.sponsor-close').removeClass('show');
            });
        }
    }
    addAlert(message, $elem, type = "danger", dismissible = false)
    {
        let _class = "alert alert-" + type;
        if (dismissible) {
            _class += ' alert-dismissible fade show';
            message += '<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>';
        }
        $('<div class="' + _class + '">' + message + '</div>').appendTo($elem);
    }

    addAlerts(messages, $elem, type = "danger", dismissible = false)
    {
        for (let i in messages) {
            this.addAlert(messages[i], $elem, type, dismissible);
        }
    }
}

export default App;